const setItem = (key,value) => {
    window.localStorage.setItem(key,JSON.stringify(value));
}

const getItem = (key) => {
    if (window.localStorage.getItem(key)){
        return JSON.parse(window.localStorage.getItem(key));
    }
    return undefined;
}

export function setUser(user){
    setItem('user',user);
}

export function getBakUrl(){
    //return 'http://localhost:8080';
    return 'https://leadingcoach.com.ar/ws/public';
    //return 'http://localhost/ws/public';
    //return 'https://localhost/ws/public';

}
export function getUser() {
    return getItem('user');
}

export function clear(){
    window.localStorage.clear();
}

export function createUserObj(v_id ,v_name,v_email,v_img,v_is_silhouette,v_rol) {

    var user = new Object();
    user.id = v_id;
    user.name = v_name;
    user.email = v_email;
    var pic_items = new Object();
    pic_items.url = v_img;
    pic_items.width = '50';
    pic_items.height = '50';
    var pic_data = new Object();
    pic_data.data = pic_items;
    pic_data.is_silhouette = v_is_silhouette;
    user.picture = pic_data;
    user.rol = v_rol;
    user.expiration = Math.round(new Date().getTime() / 1000) + 3600*24;

    return user;
}

export  function  validarVacios(campos){
    var out = true;
    campos.map(function(item, key) {

        if (item == null || item == undefined){
            out = false;
            return;
        }
        if ( JSON.stringify(item.toString().trim()).length == '2'){
            out = false;
            return;
        }

    });
    return out;
}

