import React, { useReducer } from 'react';
import axios from 'axios';
import './styles.css';
import * as storage from '../../utils/storage'



const RefreshPassForm =({onLogin}) => {

    let ref_email = React.createRef();
    const backUrl = storage.getBakUrl();
    
    const sendInformation = (response) => {

        var email = ref_email.current.value;

        //validaciones entrada
        if(!storage.validarVacios([email])){alert('Hay campos sin completar'); return;};

        var bodyFormData = new FormData();
        bodyFormData.set('email', email);
        
        axios({
        method: 'post',
        url: backUrl+'/usr_refreshPass',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            //validaciones salida
            console.log(response.data.success);
            if(response.data.success == '0'){
                alert('El email que ha ingresado no corresponde a ningun usuario registrado, por favor coloque un email valido');
                return;
            }else{
                alert('Se ha enviado un codigo de ingreso al email solicitado. En los proximos minutos debera haber llegado a tu casilla, recorda siempre por las dudas revisar tu carpeta de \'Correo no Deseado\'.');
                window.location.reload();
                
            }

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

      };

    return (

            <form>
                <div className="form-group">
                    <input type="email" className="form-control" ref={ref_email} name="email" placeholder="Recuerda su e-mail?" />
                </div>
                 <button type="button" className="btn btn-primary btn-block"  onClick={() => sendInformation()}>Enviar</button>

            </form>

    );
};

export default RefreshPassForm;

