import React, { useReducer, useState, useEffect } from 'react';
import axios from 'axios';
import * as storage from '../../utils/storage'
import './styles.css';
import Loader from '../Loader';
import '../../utils/styles.css';
import CustomShowModal from '../CustomShowModal'

const Calendar = ({ onLogin }) => {


  const user = storage.getUser();

  // State Vars
  const [loading, setLoading] = useState(true);
  const [rutina, setViewContent] = useState([]);
  const [alumnos, setAlumnos] = useState([]);

  // Fetching Inicial
  useEffect(() => {
    try{
      async function fetchData() {
          const result = await axios(
            backUrl+'/usr_getTask/'+user.id,
          );

          setViewContent(result.data);
          setLoading(false);
      };
      async function fetchDataStu() {
        const result = await axios(
            backUrl+'/usr_getStudents/'+user.id,
        );
        setAlumnos(result.data);
        setLoading(false);
      };
      fetchDataStu();

      fetchData();
    } catch (err) {
      console.log(err);
      window.location.href = "/NotFoundPage";
    }
}, []);


  const backUrl = storage.getBakUrl();

  Date.prototype.ddmmyyy = function() {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
    
    return [(dd>9 ? '' : '0') + dd,
            (mm>9 ? '' : '0') + mm,
            this.getFullYear()            
            ].join('/');
  };

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const hoy = new Date();
  const dias = [];
  var i;
  for (i = -1; i < 14; i++) {
    dias.push(hoy.addDays(i).ddmmyyy());
  }

  function goToCreateActivity(){
    window.location.href = "/TaskForm";
  }

  function goToAddStudent(){
    window.location.href = "/StudentsForm";
  }

  return (

    <div className=" container-fluid" style={{ padding: '24px 16px', minHeight: '80vh', justifyContent: 'center' }}>
      {loading && <Loader/>}
      {!loading &&
      <div className="row paddingstyle fade-in" >
        {(rutina.length === 0) && (user.rol != 'Profesor') && //Cambiar luego por algo mejor
          <CustomShowModal state ={true} header = {'Bienvenido!'} body = {'Hola '+user.name+'! Aun no tienes actividades, debes contactar a tu entrenador para que te agende algunas. Procura pasarle tu usuario: "'+user.email+'" para que te incluya en sus rutinas. Buena Suerte!'} confirm_text = {'Cerrar'}  /> 
        }
        {(rutina.length === 0) && (user.rol == 'Profesor') && (alumnos.length !== 0) &&//Cambiar luego por algo mejor
          <CustomShowModal state ={true} header = {'Bienvenido!'} body = {'Hola '+user.name+'! Aun no has asignado actividades a tus deportistas, deberias empezar con eso de una vez 💪. Buena Suerte!'} confirm_text = {'Quizas Despues'} aditional_buton_function = {goToCreateActivity} aditional_buton_text = {'Crear Actividad'} /> 
        }
        {(alumnos.length === 0) && (user.rol == 'Profesor') && //Cambiar luego por algo mejor
          <CustomShowModal state ={true} header = {'Bienvenido!'} body = {'Hola '+user.name+'! Aun no tienes Deportistas en tus contactos, deberias agregar algunos para asignarles Ejercicios!'} confirm_text = {'Quizas Despues'} aditional_buton_function = {goToAddStudent} aditional_buton_text = {'Invitar Deportistas'} /> 
        }
        {
          dias.map(function(fecha,index){
            //return <ObjectRow obj={object} key={i} />;
          return <div className="col-lg-4 paddingrow" > 
                    <div className={ ( (rutina[fecha] != undefined) ? 'daytoday' : 'day')}>
                        <div><span role="img" aria-label="Calendar">📅</span>{fecha} { (index == 1) ? ('📌'):('') }</div>
                          <div className = "listarutinas" >
                          {
                            (rutina[fecha] != undefined) && 
                              rutina[fecha].map(function(item, i){
                                //return <ObjectRow obj={object} key={i} />;
                                  return <div className = "rutina"><a href={"/TaskView/"+item.id}>- {item.nombre} ({item.done}/{item.cant}) { (item.done == item.cant) ? ('✔️'):('') }  </a></div> 
                              })
                          }
                          </div>
                    </div>
                  </div>
          })
        }
      </div>
      }
    </div>

  );
};

export default Calendar;

