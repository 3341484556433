import React, { useReducer, useState, useEffect  } from 'react';
import axios from 'axios';
import './styles.css';
import * as storage from '../../utils/storage'
import Loader from '../Loader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import '../../utils/styles.css';

const CommentList =({ id_task, id_coach, id_student}) => {

    let ref_comentario = React.createRef();
    const user = storage.getUser();
    const backUrl = storage.getBakUrl();

  // State Vars
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ alumnoSelected, setAulmnoSelected ] = useState(-1); 
    const [alumnos, setAlumnos] = useState([]);

  // Fetching Inicial
    useEffect(() => {
      try{
        fetchDataMembers();
        //setLoading(false);
      } catch (err) {
        console.log(err);
        window.location.href = "/NotFoundPage";
      }
    }, []);

  //List Alumno Seleccionado
    const useStyles = makeStyles((theme) => ({
      root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
      },
    }));
    const classes = useStyles();

    async function fetchDataComments(alumno) {
        //setLoading(true);
        const result = await axios(
          backUrl+'/rutina_getComments/'+id_task+'/'+id_coach+'/'+alumno,
        );

        setComments(result.data);
        setLoading(false);
        if (user.rol == 'Profesor'){ //Cambiar luego por algo mejor
          document.getElementById('checkbox-list-secondary-label-'+alumno).parentElement.focus(); // Ver el tema de sincronismo
        }
    };

    async function fetchDataMembers() {
      const result = await axios(
          backUrl+'/usr_getStudentsTask/'+id_task, 
      );
      setAlumnos(result.data);
      if(user.rol == 'Profesor'){ //Cambiar luego por algo mejor
        if(id_student != undefined){
          selectAndFetchUserComments(id_student);
          //document.getElementById('checkbox-list-secondary-label-'+id_student).parentElement.focus()
        }else{
          selectAndFetchUserComments(result.data[0].id);
        } 
      }else{
        selectAndFetchUserComments(user.id);
      }
      //setLoading(false);
    };

    const selectAndFetchUserComments = (alumnoValSelected) => {
          setAulmnoSelected(alumnoValSelected);
          fetchDataComments(alumnoValSelected);
    }
    
    const sendInformation = (response) => {

        var comentario = ref_comentario.current.value;

        //validaciones entrada
        if(!storage.validarVacios([comentario])){alert('Hay campos sin completar'); return;};

        var bodyFormData = new FormData();

        if(user.rol != 'Profesor'){ //Cambiar luego por algo mejor
          bodyFormData.set('id_usuario', user.id);
          bodyFormData.set('id_task', id_task);
          bodyFormData.set('comentario', comentario);
          bodyFormData.set('id_coach', id_coach);
        }else{
          bodyFormData.set('id_usuario', user.id);
          bodyFormData.set('id_task', id_task);
          bodyFormData.set('comentario', comentario);
          bodyFormData.set('id_coach', alumnoSelected);
        }

        axios({
        method: 'post',
        url: backUrl+'/rutina_InsertComment',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success

            //validaciones salida
            if(JSON.stringify(response.data).includes('violation')){alert('La actualizacion a fallado'); return;};
            ref_comentario.current.value = ''
            fetchDataComments(alumnoSelected);

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

      };

      const deleteComment = (id_comentario) => {

        if (!(window.confirm("Seguro que quiere borrar el comentario?"))) {
            return 0;
        }

        var bodyFormData = new FormData();
        bodyFormData.set('id_comentario', id_comentario);
        
        axios({
        method: 'post',
        url: backUrl+'/comment_Delete',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            //history.push('/Calendar');
            fetchDataComments(alumnoSelected);

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

    };

    return (

        <div className="commentList">
        {loading && <Loader/>}
        {!loading &&
          <div className="fade-in">
            {(user.rol == 'Profesor') && //Cambiar luego por algo mejor
                        <div align = "center">
                            
                                <div  style={{ padding: '20px' }}>
                                    <span role="img" aria-label="Memo">🤼‍♂️</span> Deportistas: 
                                </div>
                                <div >
                                    <div className="form-group" style = {{overflowY: 'scroll',height:'100px',borderRadius:'5px'}} >
                                        <List dense className={classes.root}>
                                            {alumnos.map((value) => {
                                                const labelId = `checkbox-list-secondary-label-${value.id}`;
                                                return (
                                                    <div style = { (alumnoSelected == value.id) ? ({backgroundColor: 'lightgray'}):({}) } tabIndex={ (alumnoSelected == value.id) ? (0):(1) } >
                                                    
                                                        <ListItem key={value.id} onClick={() => selectAndFetchUserComments(value.id)} button>
                                                            <ListItemAvatar>
                                                            <Avatar
                                                                alt={`${value.nombre+' '+value.apellido}`}
                                                                src={`${value.img}`}
                                                            />
                                                            </ListItemAvatar>
                                                            <ListItemText id={labelId} primary={`${value.nombre+' '+value.apellido}`} />
                                                            {(value.rate != null ) && <ListItemText align='right' id={labelId+'2'} primary={`${'🔥 '+value.rate+'%'}`} /> }
                                                            <ListItemSecondaryAction>
                                                              {(value.num_comment != 0 ) && <span className="badge badge-secondary">{value.num_comment}</span> }
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    

                                                    </div>

                                                );
                                            })}
                                        </List>
                                    </div>
                                </div>
                            
                        </div>
            }
            <h5 className="text-muted mb-4">
              <span className="badge badge-success">{comments.length}</span>{" "}
              Comentario{comments.length > 0 ? "s" : ""}
            </h5>

      
            {comments.map((comment, index) => (
              
              <div key={comment.id_comment} className="media mb-3">
                <img
                  className="mr-3 bg-light rounded"
                  width="48"
                  height="48"
                  src={comment.img}
                  alt={comment.nombre+''+comment.apellido}
                />
          
                <div className="media-body p-2 shadow-sm rounded bg-light border">
                  <small className="float-right text-muted">{comment.fecha} {(comment.id_creador == user.id) && <a style = {{cursor: 'pointer'}} onClick={() => deleteComment(comment.id_comment)} >❌</a>}</small>
                  <h6 className="mt-0 mb-1 text-muted">{comment.nombre+' '+comment.apellido}</h6>
                  {comment.comment}
                </div>
              </div>
            ))}

            <textarea
                  ref={ref_comentario}
                  defaultValue=''
                  className="form-control"
                  placeholder="Comentar ✏️"
                  name="message"
                  rows="5"
            />
              
              <button type="button" className="btn btn-primary btn-block"  onClick={() => sendInformation()}>Enviar</button>
          </div>
        }
        </div>

    );
};

export default CommentList;

