import { Modal, Button } from 'react-bootstrap'
import React, { useReducer,useState,useEffect } from 'react';
import './styles.css';

const CustomShowModal = ({ state = false, header = 'Header', body = 'body',confirm_text = 'OK', aditional_buton_function = null, aditional_buton_text = 'ENVIAR' }) => {

  const [show, setShow] = useState(state);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return (
      <>
 
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{header}</Modal.Title>
          </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ minWidth: '100px' }} onClick={handleClose}>
              {confirm_text}
            </Button>
            {(aditional_buton_function != null) && 
              <Button variant="primary"  style={{ minWidth: '100px' }} onClick={aditional_buton_function}>
              {aditional_buton_text}
              </Button>
            }
          </Modal.Footer>
        </Modal>
      </>
    );


};

export default CustomShowModal