import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage =() => {

    return (
        <div className= "center-page" style={{ justifyContent: 'center' }}>
            <div>
            <img src="/NotFoundPage.jpg" style={{width:"100%"}}  />
            <p style={{textAlign:"center"}}>
              <Link className="btn btn-primary btn-block" to="/">Volver </Link>
            </p>
            </div>
        </div>

    );
};
export default NotFoundPage;