import React, {useState,useEffect} from 'react';
import './styles.css';
import NewUserForm from '../NewUserForm';
import NormalLoginForm from '../NormalLoginForm';
import RefreshPassForm from '../RefreshPassForm';


const NormalLogin =({onLogin}) => {

    const [newUser, setNewUser] = useState(null);
    const [refreshPass, setRefreshPass] = useState(null);

    const goCreateNewUser = (newUser) => {
        setNewUser(newUser)
    };

    const goRefresPass = (refreshPass) => {
        setRefreshPass(refreshPass)
    };
  
    const goNormalLogin = () => {
        setNewUser(null);
    }

    return (

        <div className="loginWrapper" style={{height: '60%'}}>
        {!newUser && !refreshPass &&
            <NormalLoginForm goCreateNewUser={goCreateNewUser} goRefresPass={goRefresPass} onLogin={onLogin} />
        }
        {newUser && 
            <NewUserForm onLogin={onLogin} />
        }
        {refreshPass && 
            <RefreshPassForm  />
        }
        </div>
    );
};

export default NormalLogin;

