import React from 'react';
import './styles.css';

const Header = (props) => {
    return(
        <nav className="navbar navbar-light general-colors">
            {props.children && <div style ={{ width: '10%'}}></div>}
            <div style = {{margin: '0 auto'}}>
                <img src="/logo310.png" width="100" height="100" className="d-inline-block align-top mr-sm-2" alt="Logo" />
                <img src="/flyer.png" width="195" style = {{padding: '40px 10px'}}  className="d-inline-block align-top mr-sm-2" alt="Logo" />
                
            </div>
            {props.children}
        </nav>
    )
};

export default Header; 

