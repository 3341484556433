import React, { useState, useEffect } from 'react';
import Header from './components/Layout/Header';
import FBLoginButton from './components/FBLoginButton'
import NormalLogin from './components/NormalLogin'
import EditUserForm from './components/EditUserForm'
import Calendar from './components/Calendar'
import TaskForm from './components/TaskForm'
import TaskView from './components/TaskView'
import StudentsForm from './components/StudentsForm'
import * as storage from './utils/storage'
import UserLogued from './components/UserLogued'
import ActivityList from './components/ActivityList'
import NotFoundPage from './components/NotFoundPage'
import Loader from './components/Loader'
import PaymentView from './components/PaymentView'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './utils/styles.css';

function App(dir) {



  const [user, setUser] = useState(null);

  const onLogin = (user) => {
    storage.setUser(user);
    setUser(user);
  };

  const onLogout = () => {
    storage.clear();
    setUser(null);
  }

  useEffect(() => {
    const checkSession = () => {
      const user = storage.getUser();
      if (user) {
        setUser(user);
        if((Math.round(new Date().getTime() / 1000) > user.expiration) || user.expiration == undefined){
          onLogout();
        }
      }

    };
    checkSession();

  }, []);

  return (
    <div className="container-fluid" >
      <Header>
        {user && <UserLogued user={user} onLogout={onLogout} />}
      </Header>
      <div className="fade-in">
        {!user &&
          <div className="container-fluid" >
            <div className="row" style={{  backgroundImage: "url('back"+(Math.floor(Math.random() * 4) + 1)+"_blur.jpg')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundPositiony: 'initial', backgroundSize: 'cover' }}>
              <div className="col-sm-4" >
              </div>
              <div className="col-sm-4" style={{ padding: '24px 16px', minHeight: '80vh',justifyContent: 'center' }} >
                <NormalLogin onLogin={onLogin} />
                <FBLoginButton onLogin={onLogin} />
              </div>
              <div className="col-sm-4" >
              </div>
            </div>
          </div>
        }
        {user && !storage.validarVacios([user.email, user.name, user.rol]) &&
          <div >
            <div className="bg-info text-white " style={{ textAlign: 'center' }}>Por favor corroborar los siguientes datos</div>
                <EditUserForm user={user} onLogin={onLogin} />
          </div>
        }
      </div>
      {user &&  user.rol == 'Profesor' && //Cambiar luego por algo mejor
        <Router>
          <Switch>
          <Route exact path="/">
              <ActivityList />
            </Route>
            <Route exact path="/ActivityList">
              <ActivityList />
            </Route>
            <Route exact path="/Calendar">
              <Calendar />
            </Route>
            <Route exact path="/PaymentView">
              <PaymentView />
            </Route>
            <Route exact path="/TaskForm/:id_task/:action">
              <TaskForm />
            </Route>
            <Route exact path="/TaskForm">
              <TaskForm />
            </Route>
            <Route exact path="/EditUserForm">
              <EditUserForm />
            </Route>
            <Route exact path="/TaskView/:id_task">
              <TaskView />
            </Route>
            <Route exact path="/TaskView/:id_task/:id_alumno">
              <TaskView />
            </Route>
            <Route exact path="/StudentsForm">
              <StudentsForm />
            </Route>
            <Route exact path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </Router>
      }
      {user &&  user.rol != 'Profesor' && //Cambiar luego por algo mejor
        <Router>
          <Switch>
          <Route exact path="/">
              <Calendar />
            </Route>
            <Route exact path="/ActivityList">
              <ActivityList />
            </Route>
            <Route exact path="/Calendar">
              <Calendar />
            </Route>
            <Route exact path="/PaymentView">
              <PaymentView />
            </Route>
            <Route exact path="/EditUserForm">
              <EditUserForm />
            </Route>
            <Route exact path="/TaskView/:id_task">
              <TaskView />
            </Route>
            <Route exact path="/TaskView/:id_task/:id_alumno">
              <TaskView />
            </Route>
            <Route exact path="/StudentsForm">
              <StudentsForm />
            </Route>
            <Route exact path="/Loader">
              <Loader />
            </Route>
            <Route exact path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </Router>
      }
    </div>
  );
}

export default App;
