import React, { useReducer } from 'react';
import axios from 'axios';
import './styles.css';
import * as storage from '../../utils/storage'


const NewUserForm =({onLogin}) => {

    let ref_email = React.createRef();
    let ref_nombre = React.createRef();
    let ref_apellido = React.createRef();
    let ref_password = React.createRef();
    let ref_repassword = React.createRef();
    let ref_rol = React.createRef();

    const backUrl = storage.getBakUrl();
    //const rol_opt = ['Deportista','Profesor'];
    const rol_opt = ['Deportista'];
    
    const sendInformation = (response) => {

        var email = ref_email.current.value;
        var nombre = ref_nombre.current.value;
        var apellido = ref_apellido.current.value;
        var password = ref_password.current.value;
        var repassword = ref_repassword.current.value;
        var rol = ref_rol.current.value;

        //validaciones entrada
        if(!storage.validarVacios([email,nombre,apellido,password,repassword,rol])){alert('Hay campos sin completar'); return;};
        if (password!=repassword){alert('Los Passwords no coinciden'); return;};


        var bodyFormData = new FormData();
        bodyFormData.set('nombre', nombre);
        bodyFormData.set('apellido', apellido);
        bodyFormData.set('password', password);
        bodyFormData.set('email', email);
        bodyFormData.set('rol', rol);
        bodyFormData.set('img','/generic_user.png');
        
        axios({
        method: 'post',
        url: backUrl+'/usr_insert',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            //validaciones salida
            if(JSON.stringify(response.data).includes('violation: 1062')){alert('El email que intenta ingresar ya se encuetra registrado en la pagina'); return;};
            //creo objeto user para insertar en sesion
            var user = storage.createUserObj(response.data.id,response.data.nombre+" "+response.data.apellido,response.data.email,response.data.img,false,response.data.rol)

            //{"id":"10222960445145339","name":"Nicolás Bolzán","email":"nicolasbolzan@yahoo.com.ar","picture":{"data":{"height":50,"is_silhouette":false,"url":"https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=10222960445145339&height=50&width=50&ext=1595038370&hash=AeQlkIr_HDqSXuPh","width":50}}}
    
            onLogin(user);

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

      };

    return (

            <form>
                <div className="form-group">
                    <input type="email" className="form-control" ref={ref_email} name="email" placeholder="Ingresar email" />
                </div>
                <div className="form-group">
                    <input type="nombre" className="form-control" ref={ref_nombre} name="nombre" placeholder="Ingresar nombre" />
                </div>

                <div className="form-group">
                    <input type="apellido" className="form-control" ref={ref_apellido} name="apellido" placeholder="Ingresar apellido" />
                </div>

                <div className="form-group">
                    <input type="password" className="form-control" ref={ref_password} name="password" placeholder="Ingresar password" />
                </div>

                <div className="form-group">
                    <input type="password" className="form-control" ref={ref_repassword} name="repassword" placeholder="Repetir password" />
                </div>
                
                <div className="form-group"  >
                    <select className="form-control" ref={ref_rol} name="rol">
                        {
                            rol_opt.map(function(item, i){
                                return <option key={i} value={item} >{item}</option>
                            })  
                        }
                    </select>
                </div>

                <button type="button" className="btn btn-primary btn-block"  onClick={() => sendInformation()}>Enviar</button>

            </form>

    );
};

export default NewUserForm;

