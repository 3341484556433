import React from 'react';
import axios from 'axios';
import './styles.css';
import * as storage from '../../utils/storage'

const FBLoginButton =({onLogin}) => {

  const backUrl = storage.getBakUrl();


  function  verifyCreateUser(user,user_fb){

    if(user == null){ 
      var bodyFormData = new FormData();
      bodyFormData.set('nombre', user_fb.name.split(' ')[0]);
      bodyFormData.set('apellido', user_fb.name.split(' ')[1]);
      bodyFormData.set('password', 'NOPASS');
      bodyFormData.set('email', user_fb.email);
      bodyFormData.set('rol', '');
      bodyFormData.set('img',user_fb.picture.data.url);
      axios({
        method: 'post',
        url: backUrl+'/usr_insert',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            user = storage.createUserObj(response.data.id,response.data.nombre+" "+response.data.apellido,response.data.email,response.data.img,false,response.data.rol);
            onLogin(user);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }else{
      var bodyFormData = new FormData();
      bodyFormData.set('nombre', user_fb.name.split(' ')[0]);
      bodyFormData.set('apellido', user_fb.name.split(' ')[1]);
      bodyFormData.set('img',user_fb.picture.data.url);
      bodyFormData.set('email', user_fb.email);
      bodyFormData.set('id_user', user.id);
      axios({
        method: 'post',
        url: backUrl+'/usr_refresh_fb',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            onLogin(user);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }
    //onLogin(user);
}

  const facebookLogin = () => { 
    if(!window.FB) return;

    window.FB.getLoginStatus(response => {
      if(response.status === "connected"){
        facebookLoginHandler(response);
      }else{
        window.FB.login(facebookLoginHandler, {scope: 'public_profile, email'})
      }
    
    });
  };

  const facebookLoginHandler= (response) => {
    if(response.status === "connected"){
        window.FB.api('/me?fields=id,name,email,picture', userData => {
          const user_fb = {
            ...userData,
            accesToken: response.authResponse.accesToken
          };

          // el usuario existe en la BD?
          axios({
            method: 'get',
            url: backUrl+'/usr_selectbyemail/'+user_fb.email,
            //data: bodyFormData,
            //headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(function (response) {
                //handle success
                var user = null;
                if(response.data != false){
                  //creo objeto user para insertar en sesion
                  user = storage.createUserObj(response.data.id,response.data.nombre+" "+response.data.apellido,response.data.email,response.data.img,false,response.data.rol);
                }

                verifyCreateUser(user,user_fb)
                //{"id":"10222960445145339","name":"Nicolás Bolzán","email":"nicolasbolzan@yahoo.com.ar","picture":{"data":{"height":50,"is_silhouette":false,"url":"https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=10222960445145339&height=50&width=50&ext=1595038370&hash=AeQlkIr_HDqSXuPh","width":50}}}
        
                //onLogin(user);
    
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });

        });

    }
  };

    return (
      <div className="loginWrapper">
        <a onClick={facebookLogin} className="login">
          <span>
            Conectame con Facebook
          </span>
        </a>
      </div>
    );
};

export default FBLoginButton;

