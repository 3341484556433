import React, { useReducer,useState,useEffect } from 'react';
import './styles.css';
import axios from 'axios';
import * as storage from '../../utils/storage'
import Loader from '../Loader';
import '../../utils/styles.css';

const PaymentView =({goCreateNewUser,goRefresPass,onLogin}) => {

    const user = storage.getUser();
    const idusu = user.id;
    const backUrl = storage.getBakUrl();

    const [plan_pay, setPlan] = useState("Plan 'Hasta 5 Depostistas'");
    const [pay_link, setPayLink] = useState("https://mpago.la/1X2bvWm");
    const [last_pay, setLastPay] = useState('');
    const [trial_date, setTrialDate] = useState('');
    const [days_trial_left, setDaysTrialLeft] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetching Inicial
    useEffect(() => {
        try{
            async function fetchData() {
                const result = await axios(
                    backUrl+'/usr_paymentStatus/'+idusu, 
                );
                setLastPay(result.data.last_payment_date);
                setTrialDate(result.data.trial_date);
                var diff = result.data.trial_diff;
                if(Number(result.data.trial_diff) < 0){
                    diff = '0';
                }

                if(result.data.cant_followers == null || result.data.cant_followers <= 5 ){
                    setPlan("Plan 'Hasta 5 Depostistas'");
                    setPayLink("https://mpago.la/1X2bvWm");
                }else if(result.data.cant_followers <= 10 ){
                    setPlan("Plan 'Hasta 10 Depostistas'");
                    setPayLink("https://mpago.la/1LG97y8");
                }else if(result.data.cant_followers <= 15 ){
                    setPlan("Plan 'Hasta 15 Depostistas'");
                    setPayLink("https://mpago.la/2tJHB6t");
                }else if(result.data.cant_followers <= 20 ){
                    setPlan("Plan 'Hasta 20 Depostistas'");
                    setPayLink("https://mpago.la/2yj4e4b");
                }else if(result.data.cant_followers <= 25 ){
                    setPlan("Plan 'Hasta 25 Depostistas'");
                    setPayLink("https://mpago.la/2sm3Tpw");
                }else if(result.data.cant_followers <= 30 ){
                    setPlan("Plan 'Hasta 30 Depostistas'");
                    setPayLink("https://mpago.la/1tic4Cc");
                }else if(result.data.cant_followers <= 35 ){
                    setPlan("Plan 'Hasta 35 Depostistas'");
                    setPayLink("https://mpago.la/1Qr44HD");
                }else if(result.data.cant_followers <= 40 ){
                    setPlan("Plan 'Hasta 40 Depostistas'");
                    setPayLink("https://mpago.la/2YeXf8D");
                }else if(result.data.cant_followers > 40 ){
                    setPlan("Plan 'Mas de 40 Depostistas'");
                    setPayLink("https://mpago.la/1r63Mmz");
                }
                //setFollowers(result.data.cant_followers);
                /*
                if(result.data[0].trial_diff == null){
                    diff = '7';
                }
                */
                //console.log(days_trial_left);
                setDaysTrialLeft(diff);
                setLoading(false);
            };

            fetchData();
            
        } catch (err) {
            console.log(err);
            window.location.href = "/NotFoundPage";
        }
    }, []);

    const getTrial = (response) => {

        if (!(window.confirm("Seguro que quiere proseguir con el periodo de prueba?"))) {
            return 0;
        }

        var bodyFormData = new FormData();
        bodyFormData.set('idusu', idusu);
        
        axios({
        method: 'post',
        url: backUrl+'/usr_getTrial',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            user.rol = 'Profesor';
            storage.setUser(user);
            window.location.reload();
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

    };

    return (

        <div className= "center-page" style={{ justifyContent: 'center', padding: '10px 10px' }}>
        {loading && <Loader/>}
        {!loading &&
            <div className="fade-in">
                <div  style={{ padding: '5px 5px' }}>
                    <div><h5>Queres acceder a la funcionalidad de Entrenador?</h5></div>
                    <div><h6>Podes probarlo Gratis 1 semana... 👍</h6></div>
                </div>
                <div className="row" style={{ padding: '5px 5px'}}>
                    <div className="col-sm-4" style={{ padding: '5px 5px'}}>
                        <div style={{ padding: '5px 5px', backgroundColor:'lightsteelblue', borderRadius: '10px' }}>
                        <div style={{ padding: '5px 5px'}}><img src="/paso1bis.png" width="180" /></div>
                        {(days_trial_left == null) &&
                            <div style={{ padding: '5px 5px'}}><h6>Si aun no lo has usado, puedes iniciar tu periodo de Prueba Gratuita durante siete dias <a href="#" onClick={() => getTrial()} >aqui</a>.</h6> <br></br><h6 style={{padding: '0px 25px'}}>(Aun te quedan <a href="#">7</a> dias de prueba)</h6></div>
                        }
                        {(days_trial_left != null) &&
                            <div style={{ padding: '5px 5px'}}><h6>El periodo de prueba fue solicitado el dia <a href="#" >📅 {trial_date}</a>.</h6><br></br><br></br><h6 style={{padding: '0px 25px'}}>(Te quedan <a href="#">{days_trial_left}</a> dias de prueba).</h6></div>
                        }
                        </div>
                    </div>
                    <div className="col-sm-4" style={{ padding: '5px 5px'}}>
                        <div style={{ padding: '5px 5px', backgroundColor:'lightsteelblue', borderRadius: '10px' }}>
                        <div style={{ padding: '5px 5px'}}><img src="/paso2bis.png" width="180" /></div>
                        <div style={{ padding: '5px 5px'}}><h6>Luego del periodo gratuito, se debera abonar una mensualidad para mantener la funcionalidad de Entrenador.<br></br><a href={pay_link} title = "Se cobrara por el numero de Deportistas (sin repeticion) que han participado en tus rutinas en el mes pasado">{plan_pay}: <img src="/mercadopago.png" width="35" /></a></h6>{last_pay && <h8 style={{padding: '0px 25px'}}><b>Ultimo Pago:</b> <a href="#">{last_pay}</a> </h8>}{!last_pay && <h8 style={{padding: '0px 25px'}}><b>Ultimo Pago:</b> <a href="#">-</a> </h8>}</div>
                        </div>
                    </div>
                    <div className="col-sm-4" style={{ padding: '5px 5px'}}>
                        <div style={{ padding: '5px 5px', backgroundColor:'lightsteelblue', borderRadius: '10px' }}>
                        <div style={{ padding: '5px 5px'}}><img src="/paso3bis.png" width="180" /></div>
                        <div style={{ padding: '5px 5px'}}><h6>Enviar el comprobante del Pago a la siguiente direccion para la acreditacion del mismo:<br></br><br></br><br></br><div style={{padding: '0px 35px'}}><a href="mailto:info@leadingcoach.com.ar">✉️ info@leadingcoach.com.ar</a></div></h6></div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>

    );
};

export default PaymentView;