import React from 'react';
import './styles.css';
import axios from 'axios';
import * as storage from '../../utils/storage'

const NormalLoginForm =({goCreateNewUser,goRefresPass,onLogin}) => {

    let ref_email = React.createRef();
    let ref_password = React.createRef();

    const backUrl = storage.getBakUrl();

    function simpleLogin(){

          var email = ref_email.current.value;
          var password = ref_password.current.value;

          var bodyFormData = new FormData();
          bodyFormData.set('email', email);
          bodyFormData.set('password', password);
          // el usuario existe en la BD?
          axios({
            method: 'post',
            url: backUrl+'/usr_login',
            data: bodyFormData,
            //headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(function (response) {
                //handle success
                var user = null;
                if(response.data != false){
                  //creo objeto user para insertar en sesion
                  user = storage.createUserObj(response.data.id,response.data.nombre+" "+response.data.apellido,response.data.email,response.data.img,false,response.data.rol);
                  onLogin(user);
                  return;
                }else{
                    alert('Algunos de los datos ingresados no son correctos, por favor vuelva a intentarlo'); 
                    return;
                }    
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });

    }

    return (

            <form>
                <div className="form-group">
                    <input type="email" className="form-control" ref={ref_email} placeholder="Ingresar email" />
                </div>

                <div className="form-group">
                    <input type="password" className="form-control" ref={ref_password} placeholder="Ingresar password" />
                </div>

                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1"><b style ={{fontSize:'smaller'}}>Recordarme</b></label>
                    </div>
                </div>

                <button type="button" className="btn btn-primary btn-block" onClick={() => simpleLogin()}>Aceptar</button>
                <p className="forgot-password text-right">
                    <b style ={{fontSize:'smaller'}}>No recuerda el <a href="#" onClick={() => goRefresPass(true)} >password?</a></b>
                </p>
                <button type="button" className="btn btn-success btn-block" onClick={() => goCreateNewUser(true)}>Registrarme</button>
            </form>

    );
};

export default NormalLoginForm;