import React, { useReducer, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import './styles.css';
import CommentList from '../CommentList'
import * as storage from '../../utils/storage'
import Switch from '../Switch';
import Loader from '../Loader';
import RangeSlider from 'react-bootstrap-range-slider';
import '../../utils/styles.css';

const TaskView = ({ task  }) => {

    const user = storage.getUser();
    const history=useHistory()
    var   {id_task} = useParams();
    var   {id_alumno} = useParams();

    //console.log(id_alumno);

    // State Vars
    const [SwitchValue, setSwitchValue] = useState(false);
    const [valueSlider, setValueSlider ] = useState(0); 
    const [valueRate, setValueRate ] = useState(null); 
    const [rutina, setViewContent] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetching Inicial
    useEffect(() => {
        
        async function fetchDataRutinas() {
            try { 
                const result = await axios(
                    backUrl+'/rutina_getTask/'+id_task+'/'+user.id,
                );
                setViewContent(result.data);
                setSwitchValue(( result.data.hecho === 'true'));
                //var esfuerzo = result.data.intensidad;
                if(result.data.rate != null){
                    var esfuerzo = result.data.rate;
                    setValueSlider(esfuerzo);
                    setValueRate(esfuerzo)
                }

                setLoading(false);
            } catch (err) {
                console.log(err);
                window.location.href = "/NotFoundPage";
            }
        };
        fetchDataRutinas();

    }, []);

    const SendSetSwitchValue = (val) => {
 
        var bodyFormData = new FormData();
        bodyFormData.set('id_user', user.id);
        bodyFormData.set('id_task', id_task);
        bodyFormData.set('val', val);
        
        axios({
        method: 'post',
        url: backUrl+'/rutina_setDone',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            setSwitchValue(val);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

    };

    const SendSetSliderValue = (val) => {
 
        var bodyFormData = new FormData();
        bodyFormData.set('id_user', user.id);
        bodyFormData.set('id_task', id_task);
        bodyFormData.set('val', val);
        
        axios({
        method: 'post',
        url: backUrl+'/rutina_setRate',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //setValueSlider(val);
            setValueRate(val);
            //alert('Enviado!')
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

    };

    //let params = match.params;
    let ref_email = React.createRef();
    let ref_nombre = React.createRef();
    let ref_apellido = React.createRef();
    let ref_password = React.createRef();
    let ref_repassword = React.createRef();
    let ref_rol = React.createRef();
    const backUrl = storage.getBakUrl();



    const deleteTask = (response) => {

        if (!(window.confirm("Seguro que quiere borrar la rutina de trabajo?"))) {
            return 0;
        }

        if(Date.parse(rutina.inicio) <= (Date.now() - 24*3600*1000)){
            alert('No se puede borrar una tarea que ya se ha realizado');
            return 0;
        }

        var bodyFormData = new FormData();
        bodyFormData.set('id_task', id_task);
        
        axios({
        method: 'post',
        url: backUrl+'/rutina_Delete',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            history.push('/Calendar');

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

    };

    return (


        <div className= "center-page" style={{ justifyContent: 'center' }}>
        {loading && <Loader/>}
        {!loading &&
            <div className="fade-in">
            <div className="headerul">
                <h5>{rutina.titulo}{(user.rol == 'Profesor' && (rutina.id_creador == user.id) /* //Cambiar luego por algo mejor */) && <div><a style = {{cursor: 'pointer'}} href={"/TaskForm/"+id_task+"/modify"} >✏️</a> <a style = {{cursor: 'pointer'}} onClick={() => deleteTask()} >❌</a></div>}</h5>  
                <div >
                    <table style={{ width: '100%',backgroundColor: "grey" }} cellSpacing="0" cellPadding="15">
                        <tbody>
                            <tr>
                                <td align="right" style={{ width: '50%' }}>
                                    <a href={ "/TaskView/"+ rutina.prev }><img src="/prev.png" width="20" height="20" /></a> 
                                </td>
                                <td align="left" style={{ width: '50%' }}>
                                    <a href={ "/TaskView/"+ rutina.next }><img src="/next.png" width="20" height="20" /></a> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            

            <div className="row" style={{ justifyContent: 'center',padding: '0px 16px' }} >

                <div className="col-md-3" style={{ background:  '#ccc' }}>
                    <div className="row" style={{ padding: '5px 0px' }} >
                        <div className="col-md-3"><img src={rutina.img} width="50" height="50" style={{ borderRadius: '10px' }} alt={rutina.nombre+' '+rutina.apellido} /></div>
                        <div className="col-md-9" >Profesor:<br></br>{rutina.nombre+' '+rutina.apellido}</div>
                    </div>
                    <nav className="navu">
                        <ul className="navuul">
                            <li align = "center"><span role="img" aria-label="Calendar">📅 </span>{rutina.inicio}</li>
                            <li align = "center"><span role="img" aria-label="Stopwatch">⏱ </span>{rutina.duracion_segundos}</li>
                            <li align = "center"><span role="img" aria-label="fire">🔥 </span>{rutina.intensidad}%</li>
                            <li align = "center"><span role="img" aria-label="Heart">❤️ </span>{rutina.pulsaciones}</li>
                            {/*(user.rol != 'Profesor')*/ (rutina.id_creador != user.id) && //Cambiar luego por algo mejor
                                <li align = "center">
                                    <div className="row" style={{ padding: '5px 0px' }} >
                                         <div className="col-md-5" style={{ padding: '20px' }}>
                                            Finalizado: 
                                        </div>
                                            <div className="col-md-7">
                                                {(!SwitchValue) &&
                                                    <Switch
                                                        
                                                        isOn={SwitchValue}
                                                        onColor="#06D6A0"
                                                        handleToggle={() => SendSetSwitchValue(!SwitchValue)}
                                                    /> 
                                                }
                                                {(SwitchValue) &&
                                                    <div style={{ padding: '20px' }}>✔️</div>
                                                }
                                            </div>
                                    </div>
                                </li>
                            }
                            {(SwitchValue) &&
                                <li align = "center">
                                    <div className="row" style={{ padding: '5px 0px'}} >
                                        <div className="col-md-5" style={{ padding: '20px' }}>
                                            Esfuerzo: 
                                        </div>
                                            <div className="col-md-7"   >
                                            {(!valueRate) &&
                                                <div   style={{ backgroundColor: 'rgb(6, 214, 160)', borderRadius: '50px',padding: '10px'}} >
                                                <RangeSlider
                                                    variant='light'
                                                    value={valueSlider}
                                                    onChange={changeEvent => setValueSlider(changeEvent.target.value)}
                                                />
                                                </div>
                                             }
                                             {(valueRate) &&
                                                 <div style={{ padding: '20px' }}>{valueRate+'%'}</div>
                                             }
                                            </div>

                                    </div>
                                    {(!valueRate) &&
                                        <div style={{  padding: '10px' }}>
                                                <button type="button" className="btn btn-success btn-sm"  onClick={() => SendSetSliderValue(valueSlider)}>OK</button>
                                        </div>
                                    }
                                </li>
                            }
                        </ul>
                    </nav>

                </div>
                <div className="col-md-9"  style={{ background:  '#f1f1f1' }}>
                    <article className="article">
                        <div dangerouslySetInnerHTML={{ __html: rutina.rutina_html }} />
                    </article>
                </div>

            </div>
                    <CommentList id_task={id_task} id_coach={rutina.id_creador} id_student = {id_alumno} />

                
            </div>
        }
        </div>

    );
};

export default TaskView;

