import React, { useReducer,useState,useEffect } from 'react';
import axios from 'axios';
import * as storage from '../../utils/storage'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { useParams, useHistory } from 'react-router-dom';
import Loader from '../Loader';
import '../../utils/styles.css';

const TaskForm =() => {

    var {id_task} = useParams();
    var {action} = useParams();

    //REF FORM
    let ref_titulo = React.createRef();
    let ref_duracion = React.createRef();
    let ref_pulsaciones = React.createRef();
    let ref_plantilla = React.createRef();
    //let ref_cometntario = React.createRef();
    //let ref_actividades = React.createRef();

    //DEFAULT FORM
    const [def_titulo, setTitulo] = useState('');
    const [def_duracion, setDuracion] = useState('');
    const [def_pulsaciones, setPulsaciones] = useState('');
    const [def_plantilla, setPlantilla] = useState(['Prueba']);

    // Fetching Inicial
    useEffect(() => {
        try{
            async function fetchDataRutinas() {
                const result = await axios(
                    backUrl+'/rutina_getTask/'+id_task,
                );
                console.log(result.data);     
                setTitulo(result.data.titulo);
                setDuracion(result.data.duracion_minutos);
                setPulsaciones(result.data.pulsaciones);
                setValueSlider(result.data.intensidad);
                const blocksFromHTML = convertFromHTML(result.data.rutina_html);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);

                if(action == 'modify'){
                    var date_tmp = new Date(result.data.inicio);
                    date_tmp.setHours(date_tmp.getHours()+3); 
                    setDateIni(date_tmp);
                    setChecked(JSON.parse(result.data.alumnos));
                }
                
            };

            async function fetchData() {
                const result = await axios(
                    backUrl+'/usr_getStudents/'+idusu, 
                );
                setAlumnos(result.data);
            };

            async function fetchTareas() {
                const result = await axios(
                    backUrl+'/coach_tasks/'+idusu, 
                );
                setPlantilla(result.data);
            };

            fetchData();
            fetchTareas();
            if(id_task != undefined){
                fetchDataRutinas();
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            window.location.href = "/NotFoundPage";
        }
    }, []);

    const history=useHistory();
    const user = storage.getUser();
    const idusu = user.id;
    

    const backUrl = storage.getBakUrl();
    const [loading, setLoading] = useState(true);

    //SLIDER
    const [ valueSlider, setValueSlider ] = useState(0); 

    //DATEPICKER 1
    const [date1, setDateIni] = useState(new Date());
    const handleChangeIni = date => setDateIni(date);

    //TEXTAREA
    function onTextAClick(elem) {
        var clase = elem.current.getAttribute("class");
        if(clase != "Selected"){elem.current.setAttribute("class","Selected")}else{elem.current.setAttribute("class","")}
    }

    //WYSIWYGEditor 
	const [ editorState, setEditorState ] = useState(EditorState.createEmpty());
	const onEditorStateChange = editorState => {
		setEditorState(editorState);
    };
    
    //CHECKLIST 
    const useStyles = makeStyles((theme) => ({
        root: {
          width: '100%',
          maxWidth: 360,
          backgroundColor: theme.palette.background.paper,
        },
      }));
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
        newChecked.push(value);
        } else {
        newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
    };
    const [alumnos, setAlumnos] = useState([]);

    //CARGAR PLANTILLA
    function cargarPlantilla(){

        var plantilla = ref_plantilla.current.value;
        console.log(plantilla);
        if(plantilla == "-1"){
            alert('Seleccione una plantilla!');
            return 0;
        }
        window.location.href = "/TaskForm/"+plantilla+"/new";

    }
 
    Date.prototype.ddmmyyy = function() {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();
        
        return [(dd>9 ? '' : '0') + dd,
                (mm>9 ? '' : '0') + mm,
                this.getFullYear()            
                ].join('/');
    };

    const sendInformation = (response) => {

        var titulo = ref_titulo.current.value;
        var duracion = ref_duracion.current.value;
        var pulsaciones = ref_pulsaciones.current.value;
        var inicio = date1.ddmmyyy();
        var rutina_html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        var id_user = idusu;

        //validaciones entrada
        if(!storage.validarVacios([id_user,titulo,valueSlider,duracion,pulsaciones,inicio,rutina_html,JSON.stringify(checked)])){alert('Hay campos sin completar'); return;};

        var bodyFormData = new FormData();
        bodyFormData.set('id_user', id_user);
        bodyFormData.set('titulo', titulo);
        bodyFormData.set('intensidad', valueSlider);
        bodyFormData.set('duracion', duracion);
        bodyFormData.set('pulsaciones', pulsaciones);
        bodyFormData.set('inicio', inicio);
        bodyFormData.set('rutina_html',rutina_html);
        bodyFormData.set('alumnos',JSON.stringify(checked));
        
        var service = 'rutina_insert';
        if(action == 'modify'){
            bodyFormData.set('id_task', id_task);
            service = 'rutina_update';
        }else{
            bodyFormData.set('id_task', 'NOTASK');
        }
        
        axios({
        method: 'post',
        url: backUrl+'/'+service,
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            history.push('/Calendar');

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

    };

    return (
      
        <div className="row"  style = {{padding: '24px 16px',  justifyContent : 'center'}} > 
        {loading && <Loader/>}
        {!loading &&
            <form className="fade-in">
                {(def_plantilla.length != 0) && (action != 'modify') &&
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-3">
                                📁 Crear desde Rutina Anterior:
                            </div>
                            <div className="col-sm-6">
                                <select className="form-control" ref={ref_plantilla} name="rol" >
                                    <option value = "-1" disabled selected>Selecciona una plantilla</option>
                                    {
                                        def_plantilla.map(function(item, i){

                                            return <option key={i} value={item.id} >{item.titulo}</option>
                                        })  
                                    }
                                </select>
                            </div>
                            <div className="col-sm-3">
                                <button type="button" className="btn btn-secondary btn-block"  onClick={() => cargarPlantilla()}>Cargar</button>
                            </div>
                        </div>
                    </div>
                }
                <div className="form-group">
                    <input type="titulo" className="form-control" defaultValue={def_titulo} ref={ref_titulo} name="titulo" placeholder="Titulo de la tarea" />
                </div>

                <div className="form-group">
                    <label><span role="img" aria-label="fire">🔥 </span> Intensidad</label>
                    <RangeSlider
                        value={valueSlider}
                        onChange={changeEvent => setValueSlider(changeEvent.target.value)}
                    />
                </div>
                <div className="row">
                    <div className="element1 col-sm-4" style = {{/*padding: '24px 16px',*/  justifyContent : 'center'}} > 
                        <div className="form-group">
                            <div><span role="img" aria-label="Alarm Clock">⏰</span> Inicio</div>
                            <DatePicker selected={date1} onChange={handleChangeIni} /*showTimeSelect*/ minDate={new Date()} dateFormat="dd/MM/yyyy" /*dateFormat="dd/MM/yyyy hh:mm aa"*/ />
                        </div>
                    </div>
                    <div className="element1 col-sm-4" style = {{/*padding: '24px 16px',*/  justifyContent : 'center'}} > 
                        <div className="form-group">
                            <div><span role="img" aria-label="Stopwatch">⏱</span> Duracion (minutos)</div>
                            <input type="number" className="form-control"  name="duracion" defaultValue={def_duracion} ref={ref_duracion}   required></input>
                        </div>
                    </div>
                    <div className="element1 col-sm-4" style = {{/*padding: '24px 16px',*/  justifyContent : 'center'}} > 
                        <div className="form-group">
                            <div><span role="img" aria-label="Heart">❤️</span> Pulsaciones</div>
                            <input type="number" className="form-control"  name="pulsaciones" defaultValue={def_pulsaciones} ref={ref_pulsaciones} placeholder="150" required></input>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="App">
                        <header className="App-header">
                            <span className="Controls">
                            <span role="img" aria-label="Memo">📝</span> Itinerario
                            </span>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </header>
                    </div>
                </div>
                <div className="form-group" >
                    <span role="img" aria-label="Memo">🤼‍♂️</span> Deportistas
                    <div className="form-group" style = {{overflowY: 'scroll',height:'100px'}}>
                        <List dense className={classes.root}>
                            {alumnos.map((value) => {
                                const labelId = `checkbox-list-secondary-label-${value.id}`;
                                return (
                                <div>
                                    {(value.status == 'ok') && 
                                        <ListItem key={value.id} button>
                                            <ListItemAvatar>
                                            <Avatar
                                                alt={`${value.nombre+' '+value.apellido}`}
                                                src={`${value.img}`}
                                            />
                                            </ListItemAvatar>
                                            <ListItemText id={labelId} primary={`${value.nombre+' '+value.apellido}`} />
                                            <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={handleToggle(value.id)}
                                                checked={checked.indexOf(value.id) !== -1}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    }
                                </div>
                                );
                            })}
                        </List>
                    </div>
                </div>

                {(action == 'modify') && <button type="button" className="btn btn-primary btn-block"  onClick={() => sendInformation()}>Modificar</button>}
                {(action != 'modify') && <button type="button" className="btn btn-primary btn-block"  onClick={() => sendInformation()}>Crear</button>}
            </form>
        }
        </div>

     
    );
};

export default TaskForm;

