import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './styles.css';
import * as storage from '../../utils/storage'
import { useHistory } from 'react-router-dom';
import Loader from '../Loader';
import '../../utils/styles.css';

const EditUserForm =({ onLogin}) => {

    let ref_email = React.createRef();
    let ref_nombre = React.createRef();
    let ref_apellido = React.createRef();
    let ref_rol = React.createRef();
    let ref_img = React.createRef();
    let ref_password = React.createRef();
    let ref_repassword = React.createRef();
    let ref_changepass = React.createRef();
    const user = storage.getUser();
    const history=useHistory()
    const backUrl = storage.getBakUrl();
    const rol_opt = ['Deportista','Profesor'];
    //const rol_opt = ['Deportista'];

  // State Vars
    const [loadUser, setLoadUsr] = useState([]);
    const [loading, setLoading] = useState(true);
    const [picture, setPicture] = useState(null);
    const [editPass, seteditPass] = useState(null);

  // Fetching Inicial
    useEffect(() => {
        try{
            async function fetchData() {
                const result = await axios(
                backUrl+'/usr_selectbyid/'+user.id,
                );
                //console.log(result.data);
                setLoadUsr(result.data);
                setLoading(false);
            };
    
            fetchData();
        } catch (err) {
            console.log(err);
            window.location.href = "/NotFoundPage";
        }
    }, []);


    //File
    const onChangePicture = e => {
        console.log('picture: ', picture);
        setPicture(e.target.files[0]);
    };

    const CambiarPass = (response) => {
        var changepass = ref_changepass.current.checked;
        seteditPass(changepass);
    }

    const sendInformation = (response) => {

        var email = ref_email.current.value;
        var nombre = ref_nombre.current.value;
        var apellido = ref_apellido.current.value;
        var rol = ref_rol.current.value;
        var img = picture;

        if(editPass){

            var password = ref_password.current.value;
            var repassword = ref_repassword.current.value;

            //validaciones entrada
            if(!storage.validarVacios([email,nombre,apellido,rol,password])){alert('Hay campos sin completar'); return;};
            if (password!=repassword){alert('Los Passwords no coinciden'); return;};

            var bodyFormData = new FormData();
            bodyFormData.set('id', user.id);
            bodyFormData.set('nombre', nombre);
            bodyFormData.set('apellido', apellido);
            bodyFormData.set('email', email);
            bodyFormData.set('rol', rol);
            bodyFormData.set('img', img);
            bodyFormData.set('password', password);
            //bodyFormData.set('img','/generic_user.png');

        }else{

            //validaciones entrada
            if(!storage.validarVacios([email,nombre,apellido,rol])){alert('Hay campos sin completar'); return;};

            var bodyFormData = new FormData();
            bodyFormData.set('id', user.id);
            bodyFormData.set('nombre', nombre);
            bodyFormData.set('apellido', apellido);
            bodyFormData.set('email', email);
            bodyFormData.set('rol', rol);
            bodyFormData.set('img', img);
            //bodyFormData.set('img','/generic_user.png');

        }
        setLoading(true);
        axios({
        method: 'post',
        url: backUrl+'/usr_update',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            //validaciones salida
            if(JSON.stringify(response.data).includes('violation')){alert('La actualizacion a fallado'); return;};

            //creo objeto user para insertar en sesion
            var user = storage.createUserObj(response.data.id,response.data.nombre+" "+response.data.apellido,response.data.email,response.data.img,false,response.data.rol)
            if (onLogin != undefined){   //history.push('/pathname for nextpage')
                onLogin(user);
            }else{
                //alert('Cambios Aplicados!');
                storage.setUser(user);
                //history.push('/Calendar');
                window.location.reload();
            }
            

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

    };

    return (

        <div className=" container-fluid" style={{ padding: '24px 16px', minHeight: '80vh', backgroundImage: "url('bck_img_blur_al06.jpg')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundPositiony: 'initial', justifyContent: 'center' }}>
            {loading && <Loader/>}
            {!loading &&
            
            <div className="row fade-in" style={{ padding: '24px 16px', justifyContent: "center" }}>

                <form>

                    <div className="form-group">
                        <img src={loadUser.img} width="100" height="100"  style={{ borderRadius: '10px' }} alt={loadUser.img} /> 
                    </div>
                    <div className="form-group">
                        <input
                            type="file"
                            placeholder="Ingresar Foto"
                            //style={{ display: 'none' }}
                            className="form-control"
                            onChange={onChangePicture}
                        />
                    </div>

                    <div className="form-group">
                        <input  type="email" defaultValue={loadUser.email}  className="form-control" ref={ref_email} name="email" placeholder="Ingresar email" />
                    </div>
                    <div className="form-group">
                        <input type="nombre" defaultValue={loadUser.nombre} className="form-control" ref={ref_nombre} name="nombre" placeholder="Ingresar nombre" />
                    </div>

                    <div className="form-group">
                        <input type="apellido" defaultValue={loadUser.apellido} className="form-control" ref={ref_apellido} name="apellido" placeholder="Ingresar apellido" />
                    </div>
                    
                    <div className="form-group"  >
                        <select className="form-control" ref={ref_rol} name="rol" disabled >
                        {
                            rol_opt.map(function(item, i){
                                var vselected = '';
                                if(item === loadUser.rol){
                                    vselected = 'selected';
                                }
                                return <option key={i} value={item} selected={vselected}>{item}</option>
                            })  
                        }
                        </select>
                    </div>
                    
                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" ref={ref_changepass} className="custom-control-input" onClick={() => CambiarPass()} id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1"><b style ={{fontSize:'smaller'}}>Editar Password?</b></label>
                        </div>
                    </div>

                    {editPass &&
                        <div>
                            <div className="form-group">
                                <input type="password" className="form-control" ref={ref_password} name="password" placeholder="Ingresar password" />
                            </div>

                            <div className="form-group">
                                <input type="password" className="form-control" ref={ref_repassword} name="repassword" placeholder="Repetir password" />
                            </div>
                        </div>
                    }
                    <button type="button" className="btn btn-primary btn-block"  onClick={() => sendInformation()}>Guardar</button>

                </form>
            </div>
        }
        </div>


    );
};

export default EditUserForm;

