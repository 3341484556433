import React from 'react';
import '../../utils/styles.css';
import './styles.css';

const Loader = () => {
  return (
    <div className="page-mask fade-in">
      <div className="ui active dimmer ">
        <div className="ui big text loader "></div>
      </div>
    </div>
  );
};

export default Loader;