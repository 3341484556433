import React from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import './styles.css';

const UserLogued = ({ user, onLogout }) => {

    const logout = () => {
        onLogout();
        window.FB.logout(() => { });
    }

    return (

        <div>
            {(user.rol == 'Profesor') && //Cambiar luego por algo mejor
                <Navbar expand="lg" align="Left"  style={{ padding: '0px 60px'}} >
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="  general-colors">
                            <NavDropdown title={user.picture && <img src={user.picture.data.url} width="50" height="50" style={{ borderRadius: '10px' }} className="userImage" alt={user.name} />} id="basic-nav-dropdown">
                                <NavDropdown.Item href="/EditUserForm">Mis Datos</NavDropdown.Item>
                                <NavDropdown.Item href="/ActivityList">Mis Notificaciones</NavDropdown.Item>
                                <NavDropdown.Item href="/Calendar">Mis Actividades</NavDropdown.Item>
                                <NavDropdown.Item href="/StudentsForm">Mis Deportistas</NavDropdown.Item>
                                <NavDropdown.Item href="/TaskForm">Nueva Rutina +</NavDropdown.Item>
                                <NavDropdown.Item href="/PaymentView">Pagos</NavDropdown.Item>
                                <NavDropdown.Item href="mailto:info@leadingcoach.com.ar">Ayuda ❔</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/" onClick={logout} >Salir</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>

                </Navbar>
            }
            {(user.rol !== 'Profesor') && //Cambiar luego por algo mejor
                <Navbar expand="lg" align="Left"  style={{ padding: '0px 60px'}} >
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="  general-colors">
                            <NavDropdown title={user.picture && <img src={user.picture.data.url} width="50" height="50" style={{ borderRadius: '10px' }} className="userImage" alt={user.name} />} id="basic-nav-dropdown">
                                <NavDropdown.Item href="/EditUserForm">Mis Datos</NavDropdown.Item>
                                <NavDropdown.Item href="/ActivityList">Mis Notificaciones</NavDropdown.Item>
                                <NavDropdown.Item href="/Calendar">Mis Actividades</NavDropdown.Item>
                                <NavDropdown.Item href="/PaymentView">Ser Entrenador</NavDropdown.Item>
                                <NavDropdown.Item href="mailto:info@leadingcoach.com.ar">Ayuda ❔</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/" onClick={logout} >Salir</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>

                </Navbar>
            }
        </div>
    );


};

export default UserLogued