import React, { useReducer,useState,useEffect } from 'react';
import axios from 'axios';
import * as storage from '../../utils/storage'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from 'react-router-dom';
import Loader from '../Loader';
import '../../utils/styles.css';

const StudentsForm =() => {


    // State Vars
    const [checked, setChecked] = React.useState([]);
    const [alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetching Inicial
    useEffect(() => {
        try{
                async function fetchData() {
                    const result = await axios(
                        backUrl+'/usr_getStudents/'+idusu, 
                    );
                    setAlumnos(result.data);
                    setLoading(false);
                };
            fetchData();
        } catch (err) {
            console.log(err);
            window.location.href = "/NotFoundPage";
        }
    }, []);

    const history=useHistory();
    const user = storage.getUser();
    const idusu = user.id;
    let ref_new_stu = React.createRef();
    const backUrl = storage.getBakUrl();
    
    //CHECKLIST 
    const useStyles = makeStyles((theme) => ({
        root: {
          width: '100%',
          maxWidth: 320,
          backgroundColor: theme.palette.background.paper,
        },
      }));
    const classes = useStyles();

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
        newChecked.push(value);
        } else {
        newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
    };

    const deleteStudent = (id_student,email_student) => {
        //idusu
        if(!window.confirm('Desea eliminar el Deportista?')){
            return 0;
        };

        var bodyFormData = new FormData();
        bodyFormData.set('id_student', id_student);
        bodyFormData.set('email_student', email_student);
        bodyFormData.set('id_prof', idusu);

        axios({
        method: 'post',
        url: backUrl+'/student_Delete',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            window.location.reload(false);

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

    };

    const sendInformation = (usr = null) => {

        var new_stu = ref_new_stu.current.value;
        if(usr != null){
            if(!window.confirm('Desea reenviar la solicitud para '+usr+'?')){
                return 0;
            };
            new_stu = usr;
        }
        var id_user = idusu;

        //validaciones entrada
        if(!storage.validarVacios([id_user,new_stu])){alert('Hay campos sin completar'); return;};

        var bodyFormData = new FormData();
        bodyFormData.set('id_user', id_user);
        bodyFormData.set('email_alumno', new_stu);
        
        axios({
        method: 'post',
        url: backUrl+'/student_insert',
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {
            //handle success
            if(JSON.stringify(response.data).includes('RELACION OK')){alert('Alumno Agregado!');};
            if(JSON.stringify(response.data).includes('RELACION WAITING')){alert('El Alumno aun no esta regitrado en el Sistema, en cuanto se de de alta se lo listara en su lista de Alumnos');};
            window.location.reload(false);

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });

      };

    return (
      
        <div className="row"  style = {{padding: '24px 16px',  justifyContent : 'center'}} > 
        {loading && <Loader/>}
        {!loading &&
            <form className="fade-in">
                <div className="form-group">
                    <input type="titulo" className="form-control" ref={ref_new_stu} name="titulo" placeholder="Email del Alumno" />
                    <button type="button" className="btn btn-primary btn-block"  onClick={() => sendInformation()}>Invitar</button>
                </div>

                <div className="form-group" >
                    <span role="img" aria-label="Memo">🤼‍♂️</span> Deportistas
                    <div className="form-group" style = {{overflowY: 'scroll',height:'300px', width:'320px'}}>
                        <List dense className={classes.root}>
                            {alumnos.map((value) => {
                                const labelId = `checkbox-list-secondary-label-${value.id}`;
                                return (
                                    
                                    <div>
                                     {(value.status == 'ok') && 
                                        <ListItem key={value.id} >
                                            <ListItemAvatar>
                                            <Avatar
                                                alt={`${value.nombre+' '+value.apellido}`}
                                                src={`${value.img}`}
                                            />
                                            </ListItemAvatar>
                                            <ListItemText id={labelId} primary={`${value.nombre+' '+value.apellido}`} />
                                            <ListItemSecondaryAction>
                                            ✔️ <a style = {{cursor: 'pointer'}} onClick={() => deleteStudent(value.id,value.email)} >❌</a>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    }
                                     {(value.status == 'send') && 
                                        <ListItem key={value.email} >
                                            <ListItemAvatar>
                                            <Avatar
                                                alt={`${value.email}`}
                                                src={`${value.email}`}
                                            />
                                            </ListItemAvatar>
                                            <ListItemText id={labelId} primary={`${value.email}`} />
                                            <ListItemSecondaryAction >
                                            <a style = {{cursor: 'pointer'}} onClick={() => sendInformation(value.email)} >✉️</a> <a style = {{cursor: 'pointer'}} onClick={() => deleteStudent(value.id,value.email)} >❌</a>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    }
                                    </div>
  
                                );
                            })}
                        </List>
                    </div>
                </div>

                
            </form>
        }
        </div>

    );
};

export default StudentsForm;

