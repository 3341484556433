import React, { useReducer, useState, useEffect  } from 'react';
import axios from 'axios';
import './styles.css';
import * as storage from '../../utils/storage'
import Loader from '../Loader';
import '../../utils/styles.css';

const ActivityList =({ id_task}) => {

    let ref_comentario = React.createRef();
    const user = storage.getUser();
    const backUrl = storage.getBakUrl();

    // State Vars
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetching Inicial
    useEffect(() => {
        try{
            fetchDataComments();
            //setLoading(false);
        } catch (err) {
            console.log(err);
            window.location.href = "/NotFoundPage";
        }
    }, []);

    async function fetchDataComments() {

        var service = 'usr_getStudentsActivity';
        if(user.rol != 'Profesor'){ //Cambiar luego por algo mejor
            service = 'usr_getMyActivity';
        }
        const result = await axios(
          backUrl+'/'+service+'/'+user.id,
        );

        setComments(result.data);
        setLoading(false);
    };

    return (
        <div className= "center-page" style={{ justifyContent: 'center' }}>
        {loading && <Loader/>}
        {!loading &&
            <div className="commentList fade-in">
                <h5 className="text-muted mb-4">
                <span className="badge badge-success">{comments.length}</span>{" "}
                Actividad{comments.length > 0 ? "es" : ""} Reciente{comments.length > 0 ? "s" : ""}
                </h5>

        
                {comments.map((comment, index) => (
                
                <div className="media mb-3">
                    <img
                        className="mr-3 bg-light rounded"
                        width="48"
                        height="48"
                        src={comment.img}
                        alt={comment.nombre+''+comment.apellido}
                    />
                
                    <div className="media-body p-2 shadow-sm rounded bg-light border">
                        <small className="float-right text-muted">{comment.fecha}</small>
                        <h6 className="mt-0 mb-1 text-muted">{comment.nombre+' '+comment.apellido}</h6>
                        {comment.comment} <a href={comment.target}>🔍</a>
                    </div>
                </div>
                ))}
            </div>
        }
        </div>

    );
};

export default ActivityList;

